<template>
  <div>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">

      <section class="content-header p-1 px-2">
        <div class=" div-header container-fluid">
          <div style="border-bottom: 1px solid #0001" class="row mb-2">
            <div class="col-sm-12 d-flex justify-content-between align-items-center">
              <!-- <span></span> -->
              <h4 style="color: #0007" class="mt-3">
                <i class="fa fa-user-minus mx-1"></i>
                Deletar Usuário
              </h4>
              <router-link to="/admin/users" class="btn btn-outline-secondary btn-sm"
                           title="voltar">
                <i class="fa fa-arrow-left mx-1"></i>Voltar
              </router-link>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>

      <section class="content">
        <div class="row " id="NRC">
          <div class="d-flex col-12 mt-4">
            <div class="label d-flex justify-content-end col-lg-3">
              <label class="word-custom" for="name">Nome</label>
            </div>
            <div class="form-group col-lg-6">
              <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control"
                  placeholder="Nome..."
                  v-model="admin.name"
                  readonly
              />
            </div>
          </div>

          <div class="d-flex col-12">
            <div class="label d-flex justify-content-end col-lg-3">
              <label class="word-custom" for="email">Email</label>
            </div>
            <div class="form-group col-lg-6">
              <input
                  type="email"
                  id="email"
                  name="email"
                  class="form-control"
                  placeholder="Email..."
                  v-model="admin.email"
                  readonly
              />
            </div>
          </div>

          <!-- <div class="d-flex col-12">
              <div class="label d-flex justify-content-end col-lg-3">
                  <label class="word-custom" for="password">Senha</label>
              </div>
              <div class="form-group col-lg-6">
                  <input
                      type="password"
                      name="password"
                      id="password"
                      class="form-control"
                      placeholder="Senha..."
                      v-model="admin.password"
                      readonly
                  />
              </div>
          </div> -->

        </div>
        <hr/>
        <div class="row">
          <div class="col-lg-12  d-flex justify-content-center ">
            <button class="btn-big btn btn-default" @click="back()">Voltar</button>
            <button class="btn-big btn btn-danger ml-3"
                    @click="deleteAdmin()">Deletar
            </button>
          </div>
        </div>
      </section>


    </div>
    <!-- /.content-wrapper -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      admin: {},
      nameAdmin: ''
    }
  },

  methods: {
    getManager() {
      this.$tallos_admin_api.defaults.headers.common["Authorization"] =
          `Bearer ${this.$store.getters.tokenJWT}`;

      this.$tallos_admin_api.get(`auth/manager/${this.$route.params.id}`,)
          .then(resp => {
            this.admin = resp.data
            this.nameAdmin = resp.data.name
          })
          .catch(e => {
            this.$toasted.global.defaultError({msg: e.response.data})
          })
    },

    deleteAdmin() {
      this.$tallos_admin_api.defaults.headers.common["Authorization"] =
          `Bearer ${this.$store.getters.tokenJWT}`;

      this.$tallos_admin_api.delete(`auth/remove/${this.$route.params.id}`,)
          .then(resp => {
            this.$toasted.global.defaultSuccess({msg: `Admin ${this.admin.name} deletado.`})
            this.$router.push('/admin/users')
          })
          .catch(e => {
            this.$toasted.global.defaultError({msg: e.response.data})
          })
    },

    back() {
      this.$router.push('/admin/users')
    }
  },

  created() {
    this.getManager()
    document.title = 'Tallos Admin - Deletar Admin'
  },

  destroyed() {
    this.nameAdmin = ''
  }
}
</script>

<style scoped>

.content-wrapper {
  background-color: #fff;
}

.btn-big {
  width: 180px;
  padding: 8px;
}

</style>